import React from 'react'

function ThirdBanner() {
  return (
    <div className='items-container__banner items-container__banner--homeless'>
        <div className='items-container__banner-image--portrait'>
          <img src='https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2024-06-sale-update%2Fmobi-ban-3.jpg?alt=media&token=7b360c3f-c833-4ef9-bcae-2f757bb2e95f' width='250' alt='INDIGO STUFF' />
        </div>
        <div className='items-container__banner-image--landscape'>
          <img src='https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2024-06-sale-update%2Fdesk-4.jpg?alt=media&token=5c01628d-ee12-4f11-b211-b2290a1ee9f3' width='250' alt='INDIGO STUFF' />
        </div>
    </div>
  )
}

export default ThirdBanner
